import { NavLink, useSearchParams } from "react-router-dom";
import styles from "./NavigationBar.module.scss";
import HomeIcon from "../../assets/icons/HomeIcon";
import TasksIcon from "../../assets/icons/TasksIcon";
import FrensIcon from "../../assets/icons/FrensIcon";
import FaqIcon from "../../assets/icons/FaqIcon";
import WalletIcon from "../../assets/icons/WalletIcon.tsx";
// import { useAuthToken } from "../../hooks/useAuthToken.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.tsx";
import { DataState } from "../../store/slices/data.ts";
import { QUEST_NOT_COMPLETED } from "../../constants.tsx";
import VPNIcon from "../../assets/icons/VPNIcon.tsx";
// import Leaders from "../../assets/icons/Leaders.tsx";
// import Ticket from "../../assets/icons/Ticket";

type linkType = {
  linkTo: string;
  title: string;
  icon: JSX.Element;
};

function NavigationBarLink({ link }: { link: linkType }) {
  const { linkTo, title, icon } = link;

  return (
    <NavLink
      className={({ isActive }) => {
        const linkClasses = [styles.link];
        if (isActive) linkClasses.push(styles.linkActive);
        return linkClasses.join(" ");
      }}
      to={`${linkTo}`}
    >
      {icon}
      <span>{title}</span>
    </NavLink>
  );
}

function NavigationBar({ hasNewQuests }: { hasNewQuests?: boolean }) {
  const links: Array<linkType> = [
    {
      linkTo: "/",
      title: "Main",
      icon: <HomeIcon />,
    },
    {
      linkTo: "/earn/tasks",
      title: "Earn",
      icon: <TasksIcon active={hasNewQuests || false} />,
    },
    {
      linkTo: "/frens",
      title: "Frens",
      icon: <FrensIcon />,
    },
    {
      linkTo: "/wallet",
      title: "Wallet",
      icon: <WalletIcon width={"20px"} height={"20px"} />,
    },
    /*{
      linkTo: "/leaders",
      title: "Leaders",
      icon: <Leaders />,
    },
    {
      linkTo: "/lottery",
      title: "Lottery",
      icon: <Ticket width={24} height={16} />,
    },*/
    {
      linkTo: "/faq",
      title: "FAQ",
      icon: <FaqIcon />,
    },
    {
      linkTo: "/vpn",
      title: "VPN",
      icon: <VPNIcon />,
    },
  ];

  const [searchParams, _] = useSearchParams();
  const data = useSelector((state: RootState) => state.data) as DataState;

  return (
    <nav
      className={styles.navBar}
      style={{
        zIndex:
          searchParams.get("force_quest_id") &&
          data.questsData?.find(
            (quest) =>
              quest.quest_id === parseFloat(searchParams.get("force_quest_id")!)
          )?.status === QUEST_NOT_COMPLETED
            ? -1
            : 0,
      }}
    >
      {links.map((link, idx) => {
        return <NavigationBarLink link={link} key={idx} />;
      })}
    </nav>
  );
}

export default NavigationBar;
