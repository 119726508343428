function VPNIcon() {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89536 0.0127823C4.13563 0.309008 1.91603 2.33273 1.36195 5.05788C1.2886 5.41862 1.27391 5.64592 1.25863 6.65428L1.24085 7.83008L0.97241 8.03479C0.514258 8.3842 0.172979 8.92175 0.0545046 9.48067C0.00995942 9.6909 3.45463e-05 10.4667 3.45463e-05 13.7428C3.45463e-05 18.2148 -0.00914831 18.0505 0.271916 18.6092C0.61808 19.2971 1.24242 19.7808 1.98847 19.939C2.37957 20.0219 12.6309 20.0196 13.0301 19.9365C13.8718 19.7614 14.6287 19.0642 14.8972 18.2169L14.9838 17.9434V13.7233V9.50322L14.8792 9.21661C14.8216 9.05898 14.7161 8.8325 14.6447 8.71332C14.4961 8.4652 14.1424 8.08985 13.9157 7.93988L13.7613 7.83766L13.7434 6.65807C13.7281 5.64588 13.7135 5.41885 13.6401 5.05788C13.1277 2.53799 11.1989 0.606992 8.6928 0.10496C8.30995 0.0282559 7.25508 -0.0258235 6.89536 0.0127823ZM8.40544 2.6009C9.0905 2.78088 9.64251 3.09777 10.1431 3.59836C10.6414 4.09664 10.9539 4.63943 11.1434 5.33605C11.2117 5.58723 11.2275 5.78268 11.2425 6.56284L11.2603 7.49087H7.50102H3.74172L3.75953 6.56284C3.77454 5.78268 3.79032 5.58723 3.85867 5.33605C4.05134 4.62786 4.36328 4.09097 4.87793 3.58199C5.48148 2.985 6.06475 2.69558 7.03212 2.51314C7.26126 2.46988 8.11438 2.52443 8.40544 2.6009ZM7.94874 11.3019C8.67068 11.4899 9.22324 12.0904 9.34879 12.8235C9.43698 13.3382 9.26345 13.9351 8.9083 14.3386L8.75141 14.5168V15.3802V16.2436H7.50102H6.25063V15.3802V14.5168L6.10277 14.3488C5.69198 13.8821 5.53392 13.251 5.68373 12.6758C5.9467 11.6661 6.96824 11.0465 7.94874 11.3019Z"
        fill="white"
      />
    </svg>
  );
}

export default VPNIcon;
