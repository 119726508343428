function Matic({
  width,
  height,
  color,
}: {
  width: string;
  height: string;
  color: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7903_5471)">
        <mask
          id="mask0_7903_5471"
          maskUnits="userSpaceOnUse"
          x="2"
          y="3"
          width="20"
          height="18"
        >
          <path
            d="M21.5991 3.62885H2.21582V20.4889H21.5991V3.62885Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_7903_5471)">
          <path
            d="M16.8565 8.7637C16.5016 8.56154 16.0477 8.56154 15.6462 8.7637L12.8181 10.4272L10.8978 11.4842L8.1221 13.1419C7.76713 13.344 7.31325 13.344 6.91173 13.1419L4.74121 11.8307C4.38625 11.6286 4.13603 11.2243 4.13603 10.7737V8.25542C4.13603 7.8511 4.3397 7.45256 4.74121 7.19842L6.91173 5.93925C7.26669 5.7371 7.72058 5.7371 8.1221 5.93925L10.2926 7.2504C10.6476 7.45256 10.8978 7.85688 10.8978 8.3074V9.97088L12.8181 8.86189V7.15221C12.8181 6.74789 12.6144 6.34935 12.2129 6.09521L8.17447 3.72706C7.8195 3.5249 7.36562 3.5249 6.9641 3.72706L2.82674 6.14719C2.42522 6.34935 2.22156 6.75367 2.22156 7.15221V11.8827C2.22156 12.287 2.42522 12.6856 2.82674 12.9397L6.91755 15.3079C7.27251 15.51 7.7264 15.51 8.12792 15.3079L10.9036 13.6964L12.8239 12.5874L15.5996 10.9759C15.9546 10.7737 16.4085 10.7737 16.81 10.9759L18.9805 12.2351C19.3354 12.4372 19.5857 12.8415 19.5857 13.2921V15.8104C19.5857 16.2147 19.382 16.6132 18.9805 16.8674L16.8623 18.1265C16.5074 18.3287 16.0535 18.3287 15.652 18.1265L13.4756 16.8674C13.1207 16.6652 12.8705 16.2609 12.8705 15.8104V14.1989L10.9502 15.3079V16.9714C10.9502 17.3757 11.1538 17.7742 11.5553 18.0284L15.6462 20.3965C16.0011 20.5987 16.455 20.5987 16.8565 20.3965L20.9473 18.0284C21.3023 17.8262 21.5525 17.4219 21.5525 16.9714V12.1889C21.5525 11.7845 21.3488 11.386 20.9473 11.1319L16.8565 8.7637Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7903_5471">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Matic;
