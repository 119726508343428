import { useEffect, useState } from "react";
import styles from "./Quests.module.scss";
import {
  API, BETA_TESTERS,
  QUEST_NOT_COMPLETED,
  QuestInterface,
  SHOW_CLOSE_ON_FORCE_QUEST_TIMEOUT,
} from "../../constants";
import axios from "axios";
import Loader from "../../components/Loader";
import sleepcoin from "../../assets/images/sleepcoin.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setQuests, DataState } from "../../store/slices/data";
import QuestCard from "../../components/QuestCard";
import earn_bg from "../../assets/images/big_waves.png";
import { useAuthToken } from "../../hooks/useAuthToken.tsx";
import { useSearchParams } from "react-router-dom";
import Close from "../../assets/icons/Close";
import { useTimeout } from "usehooks-ts";
import AdsgramQuestCard from "../../components/AdsgramQuestCard";
import EarnNavigationBar from "../../components/EarnNavigationBar/index.tsx";
import {useWebApp} from "../../hooks/useWebApp.tsx";
import StarsQuestCard from "../../components/StarsQuestCard";

function QuestsList({
  data,
  title,
}: {
  data: Array<QuestInterface>;
  title: string;
}) {
  return (
    <>
      <h4>{title}</h4>
      {data
        ?.slice()
        .sort((a, b) => {
          if (
            a.status === "quest_completed" &&
            b.status !== "quest_completed"
          ) {
            return 1;
          }
          if (
            a.status !== "quest_completed" &&
            b.status === "quest_completed"
          ) {
            return -1;
          }
          if (a.priority > b.priority) {
            return -1;
          }
          if (a.priority < b.priority) {
            return 1;
          }
          return 0;
        })
        .map((quest: QuestInterface, idx) => {
          return (<QuestCard quest={quest} key={idx} />);
        })}
    </>
  );
}

function Quests() {
  const authToken = useAuthToken();
  const webApp = useWebApp();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.data) as DataState;
  const [searchParams, _] = useSearchParams();
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);
  const [closedForceQuest, setClosedForceQuest] = useState<boolean>(false);
  const [questsRequested, setQuestsRequested] = useState(false)

  const isBetaTester = webApp ? BETA_TESTERS.includes(webApp.initDataUnsafe?.user?.id || -1): false;
  console.log(isBetaTester, webApp?.initDataUnsafe?.user?.id, BETA_TESTERS)

  useEffect(() => {
    if (!questsRequested && authToken !== null) {
      setQuestsRequested(true)
      console.log("authToken=", authToken)
      axios.post(`${API}/tg/quests/list`, {
        auth_token: authToken,
      }).then((resp) => {
        if (resp.data instanceof Array) {
          dispatch(setQuests(resp.data));
        }
      }).catch((err) => {
        console.error(err);
        window.location.reload();
      });
    }
  }, [authToken]);

  useTimeout(() => {
    setShowCloseButton(true);
  }, SHOW_CLOSE_ON_FORCE_QUEST_TIMEOUT);

  const [isForceQuest, setIsForceQuest] = useState<boolean>(false);
  const [forceQuest, setForceQuest] = useState<QuestInterface | undefined>(
    undefined
  );

  useEffect(() => {
    if (searchParams.get("force_quest_id")) {
      setForceQuest(
        data.questsData?.filter(
          (quest) =>
            quest.quest_id === parseFloat(searchParams.get("force_quest_id")!)
        )[0]
      );
      setIsForceQuest(true);
    }
  }, [data.questsData]);
  console.log(data.questsData)
  return (authToken === null || !data.questsData) ? (
    <Loader />
  ) : (
    <>
      {!closedForceQuest &&
        isForceQuest &&
        forceQuest?.status === QUEST_NOT_COMPLETED && (
          <div className={styles.forceQuestContainer}>
            <div className={styles.forceQuestUndercover}>
              <div className={styles.forceQuestBlock}>
                <img
                  src={sleepcoin}
                  width={100}
                  height={100}
                  alt="sleepcoin"
                  style={{
                    position: "absolute",
                    top: "-50px",
                    left: 0,
                    right: 0,
                    margin: "auto",
                  }}
                />
                {showCloseButton && (
                  <Close
                    width="24px"
                    height="24px"
                    style={{
                      position: "absolute",
                      top: "30px",
                      right: "30px",
                      margin: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setClosedForceQuest(true);
                    }}
                  />
                )}
                <h3 className={styles.forceQuestBlockTitle}>
                  You have a new task
                </h3>
                <QuestCard quest={forceQuest} />
              </div>
            </div>
          </div>
        )}
      <EarnNavigationBar />
      <div
        className={styles.questsPage}
        style={{
          height: searchParams.get("force_quest_id")
            ? "calc(var(--tg-viewport-height) - 50px)"
            : "auto",
        }}
      >
        <div className={styles.infoDiv}>
          <div
            style={{
              boxShadow: "0 0 20px #F3D45D",
              width: "100px",
              height: "100px",
              borderRadius: "50%",
            }}
          >
            <img src={sleepcoin} width={100} height={100} alt="sleepcoin" />
          </div>
          <img
            src={earn_bg}
            alt="bg"
            style={{
              width: "100vw",
              position: "absolute",
              top: 0,
              zIndex: -1,
            }}
          />
          <h2>
            {
              data.questsData.filter((quest) => {
                return (
                  quest.status === "quest_not_completed" ||
                  quest.status === "quest_waiting"
                );
              }).length
            }{" "}
            tasks available
          </h2>
          <h4>We’ll reward you after each task completion</h4>
        </div>
        <div className={styles.quests}>
          <h4>Daily</h4>
          <AdsgramQuestCard/>
          {<StarsQuestCard starsType="super_strong"/>}
          {<StarsQuestCard starsType="strong"/>}
          {<StarsQuestCard starsType="light"/>}
          <QuestsList
              data={(data.questsData || []).filter((quest) => quest.quest_type === "vpn")}
              title="VPN"
          />
          <QuestsList
              data={(data.questsData || []).filter((quest) => quest.quest_type !== "socials" && quest.quest_type !== "vpn")}
              title="Partners"
          />
          <QuestsList
              data={(data.questsData || []).filter((quest) => quest.quest_type === "socials")}
              title="Socials"
          />
        </div>
      </div>
    </>
  );
}

export default Quests;
