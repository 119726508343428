import { useNavigate } from "react-router-dom";
// import { useAuthToken } from "../../hooks/useAuthToken";
import styles from "./OpenedLootbox.module.scss";
import { coinIcon } from "../../constants";
import lootbox_opened from "../../assets/images/lootbox_opened.png";


type LootboxItem = {
  prize_type: string;
  amount?: number;
  url?: string;
  name?: string;
};


function OpenedLootbox({ data }: { data: LootboxItem }) {
  // const authToken = useAuthToken();
  const navigate = useNavigate();

  switch (data.prize_type) {
    case "none":
      return (
        <div className={styles.lootbox_empty} style={{ marginTop: "60px" }}>
          <h3>This box is empty!</h3>
          <button
            className={styles.lootbox_buy_button}
            onClick={() =>
              navigate(
                `/earn/lootbox`
              )
            }
          >
            <span>OK, next time...</span>
          </button>
        </div>
      );
    case "sleepcoins":
    case "TON":
    case "lootboxes":
      return (
        <div className={styles.lootbox_opened}>
          <div className={styles.lootbox_opened_money}>
              <div>
                <span>Your reward is ...</span>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  {coinIcon(data?.prize_type, "30px", "30px")}
                  <h3>{data?.amount}</h3>
                </div>
              </div>
          </div>
          <img
            src={lootbox_opened}
            alt="lootbox_opened"
            style={{ width: "60vw", height: "60vw", marginTop: "-20px" }}
          />
        </div>
      );
  }
}

export default OpenedLootbox;
