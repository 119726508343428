import { useState } from "react";
import Lock from "../../assets/images/login_image.png";
import styles from "./Login.module.scss";
import axios from "axios";
import { API } from "../../constants";
// import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import dormint_logo from "../../assets/images/dormint_logo.png";
import { useAuthToken } from "../../hooks/useAuthToken.tsx";

function Login() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [verify, setVerify] = useState(false);
  // const [token, setToken] = useCookies(["auth_token"]);
  // const [searchParams, _] = useSearchParams();
  const authToken = useAuthToken();

  const navigate = useNavigate();

  function getLoginCode() {
    axios
      .post(`${API}/api/auth/send_email`, {
        email: email,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          setVerify(true);
        } else {
            window.location.reload()
        }
      });
  }

  function loginAccount() {
    axios
      .post(`${API}/api/auth/verify`, {
        email: email,
        code: code,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          axios
            .post(`${API}/api/auth/telegram/external`, {
              auth_token: resp.data.auth_token,
              tg_auth_token: authToken,
            })
            .then(() => {
              navigate(
                `/`
              );
            });
        } else {
            window.location.reload()
        }
      });
  }

  return (
    <div className={styles.loginPage}>
      <div className={styles.upperShadow} />
      <div className={styles.bottomShadow} />
      <h2 className={styles.title}>
        <img src={dormint_logo} width={24} height={24} alt="dormint logo" />
        <span>Dormint</span>
      </h2>
      <div className={styles.imageDiv}>
        <img src={Lock} className={styles.image} alt="dormint-login" />
      </div>
      <div className={styles.loginForm}>
        <h4 className={styles.subTitle}>Login</h4>
        <div className={styles.inputDiv}>
          <input
            className={styles.input}
            placeholder="Email address"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            className={styles.input}
            placeholder="Email verification code"
            type="password"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            style={{ opacity: !verify ? "0" : "1", transition: "0.3s" }}
          />
          <p className={styles.description}>
            By continuing, you agree to Sleep and Earn
            <br />
            <span className={styles.link}> Terms of Use </span>
            and <span className={styles.link}> Privacy Policy </span>
          </p>
        </div>
      </div>
      <button
        className={styles.button}
        onClick={() => {
          !verify ? getLoginCode() : loginAccount();
        }}
      >
        {!verify ? "Send code" : "Continue"}
      </button>
    </div>
  );
}

export default Login;
