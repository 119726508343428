function FrensIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8283 13.3904L17.9334 11.4952L14.0132 15.4154C13.3134 16.1151 12.4352 16.5714 11.4812 16.7421L13.9789 19.2398C14.2076 19.4687 14.5787 19.4687 14.8074 19.2398L19.8283 14.2191C20.0572 13.9902 20.0572 13.6191 19.8283 13.3904ZM17.1614 10.6099C17.822 9.94951 17.8221 8.87482 17.1623 8.21396L17.16 8.21169C16.9997 8.05098 16.8087 7.92415 16.5984 7.83876C16.7784 7.3833 16.8164 6.61302 16.2199 6.01642C16.2184 6.01486 16.2169 6.01318 16.2152 6.01166C16.0555 5.85257 15.8657 5.72694 15.6569 5.64212C15.837 5.18693 15.8751 4.4165 15.2789 3.82021L15.2779 3.81915C15.1145 3.65572 14.9236 3.5297 14.7156 3.4456C14.8957 2.99013 14.9336 2.2197 14.3371 1.6231C14.337 1.6231 14.337 1.62294 14.3368 1.62294L14.3364 1.62248C13.6755 0.961616 12.6004 0.961616 11.9395 1.62248L10.8286 2.73333L11.3966 3.30142C12.3433 4.24806 12.3433 5.7883 11.3968 6.73494C11.0782 7.05337 10.6868 7.2722 10.2602 7.37642C10.155 7.80613 9.93371 8.19865 9.62051 8.51107C9.30238 8.82919 8.9116 9.04787 8.48543 9.15224C8.38121 9.57826 8.16238 9.96919 7.84441 10.2872C7.52625 10.6053 7.13547 10.824 6.70926 10.9284C6.60508 11.3544 6.38625 11.7453 6.06824 12.0635C5.60973 12.5219 5 12.7745 4.35148 12.7745C3.70301 12.7745 3.09328 12.522 2.63473 12.0633L2.06664 11.4952L0.17168 13.3904C-0.0572266 13.6193 -0.0572266 13.9902 0.17168 14.2191L5.19238 19.2398C5.42129 19.4687 5.79223 19.4686 6.02113 19.2398L9.61516 15.6459H10.6277C11.5936 15.6459 12.5017 15.2696 13.1846 14.5868L17.1614 10.6099Z"
        fill="white"
      />
      <path
        d="M3.46345 11.2348C3.95388 11.7253 4.74916 11.7253 5.23958 11.2348C5.73013 10.7443 5.73013 9.94922 5.23958 9.4586C5.73013 9.94922 6.52529 9.94922 7.01583 9.4586C7.50626 8.96821 7.50626 8.17293 7.01583 7.6825C7.50626 8.17293 8.30158 8.17293 8.79201 7.6825C9.2824 7.19207 9.2824 6.3968 8.79201 5.90637C9.2824 6.3968 10.0777 6.3968 10.5681 5.90637C11.0587 5.41582 11.0587 4.62067 10.5681 4.13008L8.0638 1.62582C7.57341 1.13539 6.77814 1.13539 6.28771 1.62582C5.79728 2.11625 5.79728 2.91153 6.28771 3.40196L6.60142 3.71582C6.11099 3.22524 5.31572 3.22524 4.82529 3.71582C4.3349 4.20625 4.3349 5.00153 4.82529 5.49196L5.45291 6.11953C4.96248 5.6291 4.1672 5.6291 3.67677 6.11953C3.18619 6.60996 3.18619 7.40524 3.67677 7.89567L4.30435 8.5234C3.8138 8.03286 3.01865 8.03286 2.5281 8.5234C2.03767 9.01383 2.03767 9.80895 2.5281 10.2995L3.46345 11.2348ZM11.1109 0.793753C11.197 0.707997 11.2884 0.627734 11.3846 0.55344L11.0419 0.210745C10.9549 0.123797 10.8428 0.0663998 10.7215 0.0466795C10.6001 0.0269591 10.4756 0.0459145 10.3656 0.100862L8.91939 0.823987L10 1.90477L11.1109 0.793753Z"
        fill="white"
      />
    </svg>
  );
}

export default FrensIcon;
