import { useNavigate} from "react-router-dom";
import styles from "./PageHeader.module.scss";
// import { useAuthToken } from "../../hooks/useAuthToken";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DataState } from "../../store/slices/data";
import dormint_logo from "../../assets/images/dormint_full_logo.png";
import profile_icon from "../../assets/images/profile_icon.png";
import {BETA_TESTERS} from "../../constants.tsx";
import {useWebApp} from "../../hooks/useWebApp.tsx";


function PageHeader() {
  const navigate = useNavigate();
    const webApp = useWebApp();
  //const authToken = useAuthToken();
 // const [searchParams, _] = useSearchParams();

  const data = useSelector<RootState>((state) => state.data) as DataState;
  const { username } = data;
    const isBetaTester = BETA_TESTERS.includes(webApp?.initDataUnsafe?.user?.id || -1);
  return (
    <div className={styles.pageHeader}>
      <img width="100px" src={dormint_logo} alt="dormint profile" />
        <div
            className={styles.profileIcon}
            onClick={() => {
                if (isBetaTester) {
                    navigate(`/profile`)
                }
            }}
        >
            <span className={styles.username}>@{username}</span>
            {isBetaTester && <img
                width="26px"
                height="26px"
                src={profile_icon}
                alt="dormint profile"
            />}
        </div>
    </div>
  );
}

export default PageHeader;
