function WalletIcon({ width, height }: { width: string; height: string }) {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.52275 0.0826038C1.30701 0.381581 0.376889 1.31805 0.0816452 2.54039C0.00115448 2.87368 -0.000754071 3.0369 0.00011722 9.49936C0.000988512 15.9329 0.00327044 16.1265 0.082558 16.4583C0.376225 17.6875 1.31361 18.6236 2.54678 18.9193C2.87301 18.9975 3.08851 19 9.50109 19C15.9445 19 16.1278 18.9978 16.4601 18.9182C17.6902 18.6233 18.6254 17.6868 18.921 16.4537C18.9981 16.1323 19.0017 15.9368 19.0017 12.1547C19.0017 8.35415 18.9984 8.17848 18.9199 7.85112C18.6231 6.61289 17.6959 5.69111 16.4386 5.38425C16.0801 5.29678 16.0248 5.29587 9.43566 5.27135L2.79406 5.24662L2.43849 5.07037C1.41402 4.56266 1.03737 3.38691 1.56521 2.34456C1.69851 2.0813 2.08304 1.69677 2.3463 1.56347C2.84509 1.31088 2.40895 1.32581 9.27837 1.32581C13.6462 1.32581 15.6214 1.33955 15.7744 1.37104C16.1768 1.45381 16.6495 1.71416 16.9585 2.02326C17.4021 2.46712 17.61 2.92546 17.6739 3.601C17.7146 4.03138 17.7655 4.139 18.0061 4.30272C18.269 4.48167 18.7501 4.37351 18.9183 4.09768C19.0816 3.82982 19.0047 3.01998 18.7545 2.37406C18.3949 1.44551 17.5549 0.605545 16.6264 0.24595C15.9549 -0.0141096 16.3144 -0.00216037 9.26427 0.00111735C3.11038 0.00402165 2.82858 0.00742379 2.52275 0.0826038ZM3.85803 2.74685C3.65946 2.88198 3.56175 3.03358 3.53814 3.24307C3.51105 3.48346 3.63415 3.7307 3.84679 3.86289L4.00366 3.96043H9.50109H14.9985L15.1554 3.86289C15.368 3.7307 15.4911 3.48346 15.464 3.24307C15.4404 3.03358 15.3427 2.88198 15.1442 2.74685L15.007 2.65349H9.50109H3.9952L3.85803 2.74685ZM15.3571 10.9489C15.6425 11.083 15.8219 11.2543 15.9757 11.5396C16.105 11.7795 16.1172 11.8324 16.1172 12.1547C16.1172 12.4768 16.105 12.5301 15.9762 12.7688C15.8192 13.06 15.689 13.1857 15.3764 13.3482C15.1963 13.4417 15.1115 13.4584 14.8118 13.4592C14.4886 13.46 14.4372 13.4483 14.1967 13.3186C13.9121 13.1651 13.7436 12.9889 13.6006 12.6954C13.4657 12.4183 13.4702 11.8824 13.6097 11.5946C13.782 11.2396 14.118 10.9636 14.5006 10.8631C14.7042 10.8095 15.1573 10.855 15.3571 10.9489Z"
                  fill="white"/>
        </svg>

    );
}

export default WalletIcon;
