import styles from './WalletCard.module.scss'
import {toUserFriendlyAddress} from "@tonconnect/sdk";
import TonWalletIcon from "../../assets/icons/TonWalletIcon.tsx";
import {TonConnectUI, Wallet} from "@tonconnect/ui-react";
import Close from "../../assets/icons/Close.tsx";
import {useEffect, useState} from "react";
import Spinner from "../../assets/icons/Spinner.tsx";
// import axios from "axios";
// import {API} from "../../constants.tsx";


const WalletCard = ({ wallet, tonConnectUI, onDisconnectWallet }: {wallet: Wallet, tonConnectUI: TonConnectUI, onDisconnectWallet: () => Promise<void>}) => {
    const [balance, setBalance] = useState<string | null>(null);

    useEffect(() => {
        if (balance === null) {
            const userFriendlyAddress = toUserFriendlyAddress(wallet.account.address);
            fetch(`https://toncenter.com/api/v3/addressInformation?address=${userFriendlyAddress}&use_v2=false`).then(res => res.json()).then((result) => {
                setBalance((parseInt(result['balance']) * 1e-9).toFixed(2))
            })
        }
    }, [balance]);

    const formatHexAddress = (hexValue: string) => {
        const userFriendlyAddress = toUserFriendlyAddress(hexValue);
        return userFriendlyAddress.substring(0, 3) + '...' + userFriendlyAddress.substring(userFriendlyAddress.length - 7);
    }

    return (
        <div className={styles.walletCard} onClick={() => {
            navigator.clipboard.writeText(toUserFriendlyAddress(wallet.account.address))
        }}>
            <div className={styles.walletCardInfo}>
                <TonWalletIcon />
                <div className={styles.walletCardText}>
                    <span>{wallet.device.appName}</span>
                    <div>{formatHexAddress(wallet.account.address)}</div>
                    <div style={{display: "inline-flex"}}>{(balance) ? <>{balance}</>: <Spinner width={"16px"} height={"16px"} />} TON</div>
                </div>
            </div>
            <Close width={"17px"} height={"17px"} style={{flexShrink: 0}} onClick={async () => {
                await tonConnectUI.disconnect();
                await onDisconnectWallet();
            }} />
        </div>
    );
}

export default WalletCard;
