import { Outlet } from "react-router-dom";
import NavigationBar from "../NavigationBar";
import styles from "./PageTemplate.module.scss";
import { IsUnderMaintenance } from "../../constants.tsx";
import { RootState } from "../../store";
import {DataState, setUsername} from "../../store/slices/data.ts";
import { useSelector } from "react-redux";
import {useWebApp} from "../../hooks/useWebApp.tsx";
import {useEffect} from "react";
import Loader from "../Loader";
import store from '../../store'
import {useAuthToken} from "../../hooks/useAuthToken.tsx";


function PageTemplate() {
    const authToken = useAuthToken();
    const WebApp = useWebApp();
    useEffect(() => {
        if (WebApp) {
            store.dispatch(setUsername(WebApp.initDataUnsafe?.user?.username || ""))
        }
    }, [WebApp]);
    const data = useSelector<RootState>((state) => state.data) as DataState;
    if (!WebApp || authToken === null) {
        return <Loader />
    }
  return (
    <div className={styles.mainStyle}>
      <div
        className={
          IsUnderMaintenance ? styles.pageUnderMaintenance : styles.page
        }
        style={{
          height: "calc(var(--tg-viewport-height) - 50px)",
        }}
      >
          <Outlet />
      </div>
      {!IsUnderMaintenance && (
        <NavigationBar hasNewQuests={data.hasNewQuests} />
      )}
    </div>
  );
}

export default PageTemplate;
