import styles from "./BuyLottery.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DataState } from "../../store/slices/data";
import dormint_logo from "../../assets/images/dormint_full_logo.png";
import profile_icon from "../../assets/images/profile_icon.png";
// import { useAuthToken } from "../../hooks/useAuthToken";
import lottery_image from "../../assets/images/lottery_ticket_image.png";
import Ticket from "../../assets/icons/Ticket";
import mini_coin from "../../assets/images/mini-coin.png";


function BuyLottery() {
  const navigate = useNavigate();
  // const [searchParams, _] = useSearchParams();
  const data = useSelector<RootState>((state) => state.data) as DataState;
  const { username } = data;
  // const authToken = useAuthToken();

  return (
    <div className={styles.lotteryPage}>
      <div className={styles.upperDiv}>
        <img width="100px" src={dormint_logo} alt="dormint profile" />
        <div
          className={styles.profileIcon}
          onClick={() => {
            navigate(
              `/profile"
              )}`
            );
          }}
        >
          <span className={styles.username}>@{username}</span>
          <img
            width="26px"
            height="26px"
            src={profile_icon}
            alt="dormint profile"
          />
        </div>
      </div>
      <div className={styles.lottery}>
        <div className={styles.lottery_user_info}>
          <Ticket width={24} height={16} />
          <span>12 tickets bought</span>
        </div>
        <img
          src={lottery_image}
          alt="lottery ticket"
          style={{ width: "60vw", height: "60vw" }}
        />
        <div className={styles.lottery_info}>
          <h3>Buy lottery ticket</h3>
          <p>
            You can buy <span>only one</span> ticket everyday
          </p>
        </div>
        <button className={styles.lottery_buy_button}>
          <span>Buy</span>{" "}
          <div>
            <img src={mini_coin} alt="mini coin" width={10} height={10} />
            <span>500 SC</span>
          </div>
        </button>
      </div>
    </div>
  );
}

export default BuyLottery;
