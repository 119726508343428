function TasksIcon({ active }: { active: boolean }) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="-3 -3 24 26" fill="none">
        <path
            d="M11.0601 0H6.36C5.31998 0 4.46997 0.840012 4.46997 1.88003V2.82004C4.46997 3.86006 5.30998 4.70007 6.35 4.70007H11.0601C12.1001 4.70007 12.9401 3.86006 12.9401 2.82004V1.88003C12.9501 0.840012 12.1001 0 11.0601 0Z"
            fill="white"/>
        <path xmlns="http://www.w3.org/2000/svg"
              d="M13.9502 2.81947C13.9502 4.4095 12.6502 5.70951 11.0602 5.70951H6.36009C4.77007 5.70951 3.47005 4.4095 3.47005 2.81947C3.47005 2.25946 2.87004 1.90946 2.37004 2.16946C1.65378 2.55089 1.05481 3.12002 0.637301 3.81586C0.219795 4.5117 -0.000507304 5.30803 8.77196e-07 6.11952V15.5297C8.77196e-07 17.9897 2.01003 19.9997 4.47007 19.9997H12.9502C15.4102 19.9997 17.4203 17.9897 17.4203 15.5297V6.11952C17.4203 4.40949 16.4602 2.91947 15.0502 2.16946C14.5502 1.90946 13.9502 2.25946 13.9502 2.81947ZM9.09014 14.9497H4.71007C4.30006 14.9497 3.96006 14.6096 3.96006 14.1996C3.96006 13.7896 4.30006 13.4496 4.71007 13.4496H9.09014C9.50014 13.4496 9.84015 13.7896 9.84015 14.1996C9.84015 14.6096 9.50014 14.9497 9.09014 14.9497ZM11.7102 10.9496H4.71007C4.30006 10.9496 3.96006 10.6096 3.96006 10.1996C3.96006 9.78957 4.30006 9.44957 4.71007 9.44957H11.7102C12.1202 9.44957 12.4602 9.78957 12.4602 10.1996C12.4602 10.6096 12.1202 10.9496 11.7102 10.9496Z"
              fill="white"/>
        {(active) && (<circle fill="red" cx="18" cy="0" r="3"></circle>)}
      </svg>
  );
}

export default TasksIcon;
