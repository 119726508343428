import { useNavigate, useSearchParams } from "react-router-dom";
import BackArrow from "../../assets/icons/BackArrow";
import DownArrow from "../../assets/icons/DownArrow";
import Copy from "../../assets/icons/Copy";
// import { useState } from "react";
import QRCode from "react-qr-code";
import styles from "./Receive.module.scss";
// import {useAuthToken} from "../../hooks/useAuthToken.tsx";

function Receive() {
  const [searchParams, _] = useSearchParams();
  // const authToken = useAuthToken();
  const navigate = useNavigate();
  const userAddress = searchParams.get("address") ?? "";

  return (
    <div className={styles.receivePage}>
      <div className={styles.upperDiv}>
        <div
          className={styles.icon}
          style={{ position: "absolute" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <BackArrow width="24px" height="24px" />
        </div>
        <div className={styles.titleDiv}>
          <h2
            className={styles.title}
            onClick={() => {
              navigate(
                `/selectCoin`
              );
            }}
          >
            {searchParams.get("default_coin")}
          </h2>
          <div
            className={styles.icon}
            onClick={() => {
              navigate(
                `/selectCoin`
              );
            }}
          >
            <DownArrow width="14px" height="7px" />
          </div>
        </div>
      </div>
      <h3 className={styles.subTitle}>
        {"Receive " + searchParams.get("default_coin")}
      </h3>
      <div className={styles.mainDiv}>
        <div className={styles.addressDiv}>
          <span>{userAddress}</span>
          <div className={styles.icon}>
            <Copy width="16px" height="16px" />
          </div>
        </div>
        <div className={styles.qrCode}>
          <QRCode value={userAddress} />
        </div>
      </div>
    </div>
  );
}

export default Receive;
