function LootboxIcon({ width, height }: { width: number; height: number }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_67_149)">
        <path
          d="M18.6956 7.88485H17.2398C17.4318 7.58041 17.5586 7.23868 17.5947 6.87726C17.7101 5.72223 17.1029 4.68683 16.0691 4.22778C15.1189 3.80583 14.051 3.97832 13.2821 4.67697L11.4438 6.3459C11.0858 5.95366 10.5712 5.70713 9.99998 5.70713C9.42779 5.70713 8.91233 5.95444 8.55424 6.3479L6.71362 4.67677C5.94343 3.97813 4.87601 3.8063 3.9262 4.22802C2.89269 4.6871 2.28535 5.72286 2.40113 6.87785C2.43734 7.23899 2.56398 7.58056 2.75597 7.88485H1.30433C0.583944 7.88485 0 8.46986 0 9.19147V11.1514C0 11.5122 0.291992 11.8048 0.652186 11.8048H19.3478C19.708 11.8048 20 11.5122 20 11.1514V9.19147C20 8.46986 19.416 7.88485 18.6956 7.88485ZM8.04346 7.66708V7.88485H4.97538C4.16476 7.88485 3.52679 7.12782 3.72925 6.28236C3.81843 5.91002 4.08671 5.59263 4.4337 5.43196C4.91019 5.21134 5.438 5.28217 5.83819 5.64503L8.04401 7.64783C8.04389 7.65429 8.04346 7.66063 8.04346 7.66708ZM16.3005 6.7038C16.2519 7.38284 15.6396 7.88493 14.96 7.88493H11.9565V7.66716C11.9565 7.65945 11.956 7.65182 11.956 7.64411L14.1276 5.67226C14.4617 5.36888 14.9293 5.22727 15.3615 5.3564C15.9816 5.54172 16.3459 6.07007 16.3005 6.7038ZM1.30433 13.1114V22.6934C1.30433 23.415 1.88828 24 2.60867 24H8.69561V13.1114H1.30433ZM11.3043 13.1114V24H17.3913C18.1116 24 18.6956 23.415 18.6956 22.6934V13.1114H11.3043Z"
          fill="url(#paint0_linear_67_149)"
        />
      </g>
      <circle
        cx="19"
        cy="5"
        r="3.7"
        fill="#FF5530"
        stroke="#353864"
        strokeWidth="1.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_67_149"
          x1="10"
          y1="35"
          x2="10"
          y2="5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CF7C00" />
          <stop offset="1" stopColor="#FFD952" />
        </linearGradient>
        <clipPath id="clip0_67_149">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LootboxIcon;
