function WindowsIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2909 4.79588L12.0234 5.33147V8.63423V11.9404H16.418H20.8125V8.09521V4.25L20.6855 4.25343C20.6134 4.25687 18.6358 4.50063 16.2909 4.79588Z"
        fill="white"
      />
      <path
        d="M6.94913 5.96318L3.23438 6.4301V9.18355V11.9404H7.07959H10.9248V8.7132V5.48596L10.7978 5.4894C10.7257 5.49283 8.99533 5.70569 6.94913 5.96318Z"
        fill="white"
      />
      <path
        d="M3.23438 15.796V18.5495L7.03839 19.0233C9.12923 19.2842 10.8596 19.497 10.8836 19.4936C10.9145 19.4936 10.9248 18.8241 10.9248 16.2664V13.0391H7.07959H3.23438V15.796Z"
        fill="white"
      />
      <path
        d="M12.0234 16.3452V19.648L16.3768 20.1904C18.7697 20.4891 20.7473 20.7329 20.7713 20.7295C20.8022 20.7295 20.8125 19.9364 20.8125 16.8842V13.039H16.418H12.0234V16.3452Z"
        fill="white"
      />
    </svg>
  );
}

export default WindowsIcon;
