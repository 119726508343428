import profile_background from "../../assets/images/profile_icon_bg.png";
import styles from "./ProfileAvatar.module.scss";

function ProfileAvatar({
  username,
  width,
  height,
}: {
  username: string | undefined;
  width: number;
  height: number;
}) {
  username = username || "Anonymous";
  return (
    <div
      className={styles.profileAvatarContainer}
      style={{ width: width, height: height }}
    >
      <img
        src={profile_background}
        width={width}
        height={height}
        alt=""
        className={styles.profileAvatarBg}
      />
      <span
        className={styles.profileAvatarUsername}
        style={{ fontSize: width / 3 + "px", lineHeight: width / 2.5 + "px" }}
      >
        {username.charAt(0).toUpperCase() + username.charAt(1).toUpperCase()}
      </span>
    </div>
  );
}

export default ProfileAvatar;
