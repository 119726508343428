import { useNavigate } from "react-router-dom";
import BackArrow from "../../assets/icons/BackArrow";
import robot from "../../assets/images/robot.png";
import styles from "./ComingSoon.module.scss";
// import {useAuthToken} from "../../hooks/useAuthToken.tsx";

function ComingSoon() {
  const navigate = useNavigate();
  // const [searchParams, _] = useSearchParams();
  // const authToken = useAuthToken();

  return (
    <div className={styles.soonPage}>
      <div
        className={styles.icon}
        onClick={() => {
          navigate(
            `/`
          );
        }}
      >
        <BackArrow width="24px" height="24px" />
      </div>
      <div className={styles.imgContainer}>
        <div>
          <img className={styles.img} src={robot} alt="dormint" />
        </div>
      </div>
      <h2 className={styles.title}>This feature is coming soon!</h2>
      <button
        className={styles.button}
        onClick={() => {
          navigate(
            `/`
          );
        }}
      >
        OK
      </button>
    </div>
  );
}

export default ComingSoon;
