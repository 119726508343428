function FaqIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.588 10.3908V14.4872C13.588 14.8868 13.588 15.1866 13.4881 15.4863C13.0884 16.985 11.8895 17.8842 10.191 17.8842H7.49337L4.49602 19.8824C4.09638 20.1821 3.49691 19.8824 3.49691 19.2829V17.8842C2.49779 17.8842 1.59859 17.5844 0.999116 16.985C0.299735 16.2856 0 15.4863 0 14.4872V10.3908C0 8.49248 1.19894 7.19363 2.99735 6.9938H10.191C12.1892 6.9938 13.588 8.39257 13.588 10.3908Z"
        fill="white"
      />
      <path
        d="M15.7862 13.588C17.085 13.588 18.0842 13.1883 18.8834 12.389C19.6827 11.6897 20.0824 10.5906 20.0824 9.29178V4.2962C19.9825 1.89832 18.0842 0 15.7862 0H7.2937C4.89582 0 2.9975 1.89832 2.9975 4.2962V4.99558C2.9975 5.29532 3.19732 5.49514 3.49706 5.49514H10.1911C12.8887 5.49514 15.0868 7.69319 15.0868 10.3908V13.0884C15.0868 13.3882 15.2866 13.588 15.5864 13.588H15.7862Z"
        fill="white"
      />
    </svg>
  );
}

export default FaqIcon;
