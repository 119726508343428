import {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {WebAppContext} from "../contexts/WebAppContexts.tsx";
import axios from "axios";
import {API} from "../constants.tsx";
import store, {RootState} from "../store";
import {DataState, setAuthToken} from "../store/slices/data.ts";
import {useSelector} from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import useGeoLocation from "react-ipgeolocation";


export const useAuthToken = () => {
    const { WebApp } = useContext(WebAppContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _] = useSearchParams();
    const data = useSelector<RootState>((state) => state.data) as DataState;
    const mayBeAuthToken = searchParams.get("tg_auth_token");
    const geo = useGeoLocation();
    const [tokenRequested, setTokenRequested] = useState(false);

    useEffect(() => {
        if (data.authToken === null) {
            if (mayBeAuthToken !== null) {
                store.dispatch(setAuthToken(mayBeAuthToken));
            } else {
                if ((geo.country || !geo.isLoading) && !tokenRequested) {
                    setTokenRequested(true);
                    const geo_code = geo.country? geo.country: "Unknown";

                    axios
                        .get(`${API}/api/auth/telegram/verify?${WebApp?.initData}&geo_code=${geo_code}`)
                        .then((resp) => {
                            if (resp.data !== null) {
                                store.dispatch(setAuthToken(resp.data));
                            } else {
                                setTokenRequested(false);
                            }
                        });
                }
            }
        }
    }, [geo.country]);
    console.log(data.authToken, mayBeAuthToken)
    return data.authToken || mayBeAuthToken;
};
