

function TonWalletIcon() {
    return (<svg width="49" height="49" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8001_13422)">
                <path
                    d="M20 40.5C31.0457 40.5 40 31.5457 40 20.5C40 9.45429 31.0457 0.5 20 0.5C8.95429 0.5 0 9.45429 0 20.5C0 31.5457 8.95429 40.5 20 40.5Z"
                    fill="#0098EA"/>
                <path
                    d="M26.8288 11.6626H13.1704C10.6591 11.6626 9.06742 14.3715 10.3309 16.5615L18.7603 31.172C19.3104 32.126 20.6889 32.126 21.2389 31.172L29.6701 16.5615C30.9318 14.375 29.3401 11.6626 26.8305 11.6626H26.8288ZM18.7534 26.7905L16.9176 23.2376L12.4881 15.3152C12.1958 14.8082 12.5568 14.1584 13.1687 14.1584H18.7517V26.7922L18.7534 26.7905ZM27.5077 15.3135L23.0798 23.2393L21.2441 26.7905V14.1567H26.8271C27.439 14.1567 27.7999 14.8065 27.5077 15.3135Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_8001_13422">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TonWalletIcon
