function AppleIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6938 7.99992C16.7391 8.11242 17.7281 8.63273 18.3844 9.42023L18.6141 9.69679L18.4359 9.81398C18.1313 10.0155 17.625 10.5405 17.3906 10.8921C16.4391 12.3312 16.5328 14.1874 17.625 15.4765C17.9016 15.8046 18.2625 16.0999 18.6094 16.3015C19.0969 16.578 19.0641 16.5124 18.9188 16.9343C18.5109 18.1062 17.325 19.9484 16.5797 20.5671C15.7922 21.2234 15.0469 21.303 13.8891 20.8484C12.6281 20.3515 11.9766 20.3421 10.7766 20.8155C9.57656 21.2843 9.08906 21.289 8.38594 20.839C7.56562 20.3093 6.37969 18.678 5.77031 17.2343C4.6125 14.4874 4.59844 11.778 5.7375 10.0155C6.05625 9.52336 6.25781 9.29367 6.64219 8.98429C7.29375 8.45929 8.02969 8.14523 8.85 8.04679C9.52031 7.96242 9.95625 8.05617 11.2734 8.56242C12.0141 8.84836 12.1359 8.84367 13.1812 8.44992C14.4609 7.97179 14.8172 7.90617 15.6938 7.99992Z"
        fill="white"
      />
      <path
        d="M15.4078 4.28273C15.3797 4.86867 15.2953 5.21086 15.0656 5.69836C14.5125 6.87492 13.4672 7.65304 12.3047 7.75148L11.9531 7.77961V7.43273C11.9578 6.53273 12.3047 5.70304 12.9797 4.99054C13.3547 4.59679 13.7953 4.27336 14.2359 4.08117C14.5453 3.94054 15.0984 3.78586 15.3 3.78586L15.4359 3.78117L15.4078 4.28273Z"
        fill="white"
      />
    </svg>
  );
}

export default AppleIcon;
