import { useState, useEffect, useContext } from "react";
import styles from "./Profile.module.scss";
//import Mail from "../../assets/icons/Mail";
//import Discord from "../../assets/icons/Discord";
//import Twitter from "../../assets/icons/Twitter";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import axios from "axios";
// import { API } from "../../constants";
// import { useCookies } from "react-cookie";
import { WebAppContext } from "../../contexts/WebAppContexts";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store";
import { DataState, setUser } from "../../store/slices/data";
import profile_bg from "../../assets/images/profile_page_bg.png";
import ProfileAvatar from "../../components/ProfileAvatar";
// import {useAuthToken} from "../../hooks/useAuthToken.tsx";
import TonConnect from "../../assets/icons/TonConnect.tsx";
import FSLAuthorization from 'fsl-authorization';

/*
declare global {
    interface Window {
        FSLAuthorization:
    }
}

 */

function Profile() {
  const { WebApp } = useContext(WebAppContext);
  // const [searchParams, _] = useSearchParams();
  //const authToken = useAuthToken();
  // const navigate = useNavigate();
  // const [_cookies, _setCookie, removeCookie] = useCookies(["auth_token"]);

  const [username, setUsername] = useState<string>("");

  const data = useSelector<RootState>((state) => state.data) as DataState;
  const { userData } = data;

  useEffect(() => {
    setUsername(WebApp?.initDataUnsafe?.user?.username ?? "");

    if (!userData) {
        store.dispatch(setUser({}));
        /*
      axios
        .post(`${API}/user/info`, {
          auth_token: authToken,
        })
        .then((resp) => {
          store.dispatch(setUser(resp.data));
        });

         */
    }
  }, []);

    const fslAuthorization = FSLAuthorization.init({
        responseType: 'code', // 'code' or 'token'
        appKey: 'Your APP KEY',
        redirectUri: 'https://api-new.dormint.io/api/ouath2/fsl',
        scope: 'basic%20wallet', // Grant Scope
        state: 'test',
        usePopup: true // Popup a window instead of jump to
    });

  return !userData ? (
    <Loader />
  ) : (
    <div className={styles.profilePage}>
      <img
        src={profile_bg}
        style={{
          width: "100vw",
          position: "absolute",
          top: 0,
          zIndex: -1,
        }}
        alt=""
      />
      <div className={styles.userDiv}>
        <ProfileAvatar username={username} width={96} height={96} />
        <h3>@{username}</h3>
      </div>
        <div className={styles.groupDiv}>
            {/*
            <div
                className={styles.social}
                style={{cursor: !userData?.email ? "pointer" : "auto"}}
            >
                <div
                    onClick={() => {
                        if (!userData?.email) {
                            store.dispatch(deleteUserInfo());
                            navigate(
                                `/login?tg_auth_token=${authToken}&default_coin=${searchParams.get("default_coin")}&ref_link=${searchParams.get("ref_link")}`
                            );
                        }
                    }}
                >
                    <Mail width="24px" height="24px"/>
                    <span>{userData?.email ? userData.email : "Connect email"}</span>
                </div>
            </div>
            <form
                method="POST"
                action={"https://api.dormint.io/api/auth/discord"}
                className={styles.social}
                style={{cursor: !userData?.discord_username ? "pointer" : "auto"}}
            >
                <input
                    type="hidden"
                    id="auth_token"
                    name="auth_token"
                    value={authToken ?? ""}
                />
                <button
                    type="submit"
                    disabled={userData?.discord_username ? true : false}
                    onClick={() => {
                        store.dispatch(deleteUserInfo());
                    }}
                >
                    <Discord width="24px" height="24px"/>
                    <span>
              {userData?.discord_username
                  ? "@" + userData.discord_username
                  : "Connect Discord"}
            </span>
                </button>
            </form>
            <form
                method="POST"
                action={"https://api.dormint.io/api/auth/twitter"}
                className={styles.social}
                style={{cursor: !userData?.twitter_username ? "pointer" : "auto"}}
            >
                <input
                    type="hidden"
                    id="auth_token"
                    name="auth_token"
                    value={authToken ?? ""}
                />
                <button
                    type="submit"
                    disabled={userData?.twitter_username ? true : false}
                    onClick={() => {
                        store.dispatch(deleteUserInfo());
                    }}
                >
                    <Twitter width="24px" height="24px"/>
                    <span>
              {userData?.twitter_username
                  ? "@" + userData.twitter_username
                  : "Connect X (Twitter)"}
            </span>
                </button>
            </form>*/}
            <div
                className={styles.social}
                style={{cursor: "pointer"}}
            >
                <button
                    type="button"
                    onClick={() => {
                        fslAuthorization.signIn().then((code: unknown) => {
                            if (code) {
                                console.log(code);
                            }
                        });
                    }}
                >
                    <TonConnect width="24px" height="24px"/>
                    <span>{"Connect FSL ID Wallet"}</span>
                </button>
        </div>
    </div>
</div>
)
    ;
}

export default Profile;
