function InvitedIcon({ width, height }: { width: number; height: number }) {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none">
      <g opacity="0.9" clip-path="url(#clip0_307_208)">
        <path
          d="M9.91416 6.69524L8.96668 5.74767L7.0066 7.70774C6.65672 8.05763 6.21758 8.28575 5.74059 8.37112L6.98943 9.61997C7.10381 9.73442 7.28936 9.73442 7.40371 9.61997L9.91416 7.1096C10.0286 6.99515 10.0286 6.8096 9.91416 6.69524ZM8.5807 5.30501C8.91098 4.97481 8.91105 4.43747 8.58115 4.10704L8.58002 4.10591C8.49986 4.02555 8.40435 3.96214 8.29918 3.91944C8.38922 3.69171 8.4082 3.30657 8.10996 3.00827C8.1092 3.00749 8.10844 3.00665 8.10762 3.00589C8.02777 2.92634 7.93286 2.86353 7.82844 2.82112C7.91848 2.59353 7.93754 2.20831 7.63947 1.91017L7.63893 1.90964C7.55723 1.82792 7.46178 1.76491 7.35779 1.72286C7.44783 1.49513 7.46682 1.10991 7.16857 0.811611C7.1685 0.811611 7.1685 0.811533 7.16842 0.811533L7.1682 0.811299C6.83777 0.480869 6.3002 0.480869 5.96977 0.811299L5.41428 1.36673L5.69832 1.65077C6.17164 2.12409 6.17164 2.89421 5.6984 3.36753C5.5391 3.52675 5.3434 3.63616 5.13008 3.68827C5.0775 3.90312 4.96686 4.09939 4.81025 4.2556C4.65119 4.41466 4.4558 4.52399 4.24271 4.57618C4.19061 4.78919 4.08119 4.98466 3.92221 5.14366C3.76312 5.30272 3.56773 5.41206 3.35463 5.46425C3.30254 5.67726 3.19313 5.87273 3.03412 6.03179C2.80486 6.26099 2.5 6.38731 2.17574 6.38731C1.8515 6.38731 1.54664 6.26104 1.31736 6.03171L1.03332 5.74767L0.0858398 6.69524C-0.0286133 6.8097 -0.0286133 6.99515 0.0858398 7.1096L2.59619 9.61997C2.71064 9.73442 2.89611 9.73435 3.01057 9.61997L4.80758 7.82302H5.31387C5.79682 7.82302 6.25084 7.63487 6.59232 7.29347L8.5807 5.30501Z"
          fill="white"
        />
        <path
          d="M1.73167 5.61737C1.97688 5.86264 2.37452 5.86264 2.61973 5.61737C2.865 5.37215 2.865 4.9746 2.61973 4.72928C2.865 4.9746 3.26258 4.9746 3.50786 4.72928C3.75307 4.48409 3.75307 4.08645 3.50786 3.84124C3.75307 4.08645 4.15073 4.08645 4.39594 3.84124C4.64114 3.59602 4.64114 3.19839 4.39594 2.95317C4.64114 3.19839 5.03877 3.19839 5.28399 2.95317C5.52928 2.7079 5.52928 2.31032 5.28399 2.06503L4.03184 0.812896C3.78665 0.567681 3.38901 0.567681 3.14379 0.812896C2.89858 1.05811 2.89858 1.45575 3.14379 1.70096L3.30065 1.8579C3.05543 1.6126 2.6578 1.6126 2.41258 1.8579C2.16739 2.10311 2.16739 2.50075 2.41258 2.74596L2.72639 3.05975C2.48118 2.81454 2.08354 2.81454 1.83833 3.05975C1.59303 3.30497 1.59303 3.7026 1.83833 3.94782L2.15211 4.26169C1.90684 4.01641 1.50926 4.01641 1.26399 4.26169C1.01877 4.5069 1.01877 4.90446 1.26399 5.14975L1.73167 5.61737ZM5.55538 0.396861C5.59843 0.353983 5.64414 0.313852 5.69223 0.276705L5.52088 0.105357C5.47741 0.0618832 5.42136 0.0331847 5.36068 0.0233245C5.29999 0.0134643 5.23774 0.022942 5.18274 0.0504158L4.45963 0.411978L4.99995 0.952369L5.55538 0.396861Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_307_208">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InvitedIcon;
