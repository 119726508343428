import { useState } from "react";
import Sleepcoin from "../../assets/icons/Sleepcoin";
import Dorm from "../../assets/icons/Dorm";
import Matic from "../../assets/icons/Matic";
import CoinCard from "../../components/CoinCard";
import BackArrow from "../../assets/icons/BackArrow";
import styles from "./SelectCoin.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
// import {useAuthToken} from "../../hooks/useAuthToken.tsx";

function SelectCoin() {
  const [searchParams, _] = useSearchParams();
  const [defaultCoin, setDefaultCoin] = useState(
    searchParams.get("default_coin")
  );
  // const authToken = useAuthToken();

  const coins = [
    {
      title: "Sleepcoin",
      icon: <Sleepcoin width="24px" height="24px" color="#7883FE" />,
    },
    {
      title: "DORM",
      icon: <Dorm width="24px" height="24px" color="#7883FE" />,
    },
    {
      title: "MATIC",
      icon: <Matic width="24px" height="24px" color="#7883FE" />,
    },
  ];

  const navigate = useNavigate();

  return (
    <div className={styles.selectPage}>
      <div className={styles.upperDiv}>
        <div
          className={styles.backIcon}
          onClick={() => {
            navigate(-1);
          }}
        >
          <BackArrow width="24px" height="24px" />
        </div>
        <h2 className={styles.title}>Select Network</h2>
      </div>
      <div className={styles.cards}>
        {coins.map((coin) => {
          return (
            <CoinCard
              props={{
                coin: coin,
                isSelect: defaultCoin === coin.title,
                setCoin: setDefaultCoin,
              }}
            />
          );
        })}
      </div>
      <button
        className={styles.button}
        onClick={() => {
          navigate(
            `/`
          );
        }}
      >
        Save
      </button>
    </div>
  );
}
export default SelectCoin;
