import { Dispatch, SetStateAction } from "react";
import { coinType } from "../../constants";
import styles from "./CoinCard.module.scss";
import Check from "../../assets/icons/Check";

type propsType = {
  coin: coinType;
  isSelect: boolean;
  setCoin: Dispatch<SetStateAction<string | null>>;
};

function CoinCard({ props }: { props: propsType }) {
  const { coin, isSelect, setCoin } = props;
  return (
    <div
      className={styles.coinCard}
      onClick={() => {
        setCoin(coin.title);
      }}
    >
      <div className={styles.coinInfo}>
        <div className={styles.coinIcon}>{coin.icon}</div>
        <div className={styles.coinTitle}>{coin.title}</div>
      </div>
      {isSelect && <Check width="24px" height="24px" />}
    </div>
  );
}

export default CoinCard;
