function Dorm({
  width,
  height,
  color,
}: {
  width: string;
  height: string;
  color: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9999 8.99988C18.9999 7.93901 18.5785 6.9216 17.8283 6.17145C17.0782 5.4213 16.0607 4.99988 14.9999 4.99988V2.99988C14.9999 2.73466 14.8945 2.48031 14.707 2.29277C14.5194 2.10523 14.2651 1.99988 13.9999 1.99988C13.7347 1.99988 13.4803 2.10523 13.2928 2.29277C13.1052 2.48031 12.9999 2.73466 12.9999 2.99988V4.99988H10.9999V2.99988C10.9999 2.73466 10.8945 2.48031 10.707 2.29277C10.5194 2.10523 10.2651 1.99988 9.99988 1.99988C9.73466 1.99988 9.48031 2.10523 9.29277 2.29277C9.10524 2.48031 8.99988 2.73466 8.99988 2.99988V4.99988H5.99988C5.73466 4.99988 5.48031 5.10523 5.29277 5.29277C5.10523 5.48031 4.99988 5.73466 4.99988 5.99988C4.99988 6.26509 5.10523 6.51945 5.29277 6.70698C5.48031 6.89452 5.73466 6.99988 5.99988 6.99988H6.99988V11.9999V16.9999H5.99988C5.73466 16.9999 5.48031 17.1052 5.29277 17.2928C5.10523 17.4803 4.99988 17.7347 4.99988 17.9999C4.99988 18.2651 5.10523 18.5194 5.29277 18.707C5.48031 18.8945 5.73466 18.9999 5.99988 18.9999H8.99988V20.9999C8.99988 21.2651 9.10524 21.5194 9.29277 21.707C9.48031 21.8945 9.73466 21.9999 9.99988 21.9999C10.2651 21.9999 10.5194 21.8945 10.707 21.707C10.8945 21.5194 10.9999 21.2651 10.9999 20.9999V18.9999H12.9999V20.9999C12.9999 21.2651 13.1052 21.5194 13.2928 21.707C13.4803 21.8945 13.7347 21.9999 13.9999 21.9999C14.2651 21.9999 14.5194 21.8945 14.707 21.707C14.8945 21.5194 14.9999 21.2651 14.9999 20.9999V18.9999C16.0607 18.9999 17.0782 18.5784 17.8283 17.8283C18.5785 17.0782 18.9999 16.0607 18.9999 14.9999V11.9999V8.99988ZM9 6.99976H15C15.5304 6.99976 16.0391 7.21047 16.4142 7.58554C16.5836 7.75491 16.9019 8.11519 17 8.99976V11.9998V14.9998C16.9019 15.8843 16.5836 16.2446 16.4142 16.414C16.0391 16.789 15.5304 16.9998 15 16.9998H9V11.9998V6.99976Z"
        fill={color}
      />
    </svg>
  );
}

export default Dorm;
