function HomeIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99996 11.4548C8.34897 11.4548 7.72465 11.7134 7.26433 12.1737C6.80401 12.634 6.54541 13.2583 6.54541 13.9092V18H11.4545V13.9092C11.4545 13.2583 11.1959 12.634 10.7356 12.1737C10.2753 11.7134 9.65094 11.4548 8.99996 11.4548Z"
        fill="white"
      />
      <path
        d="M17.937 8.68779C17.896 8.58805 17.8356 8.49741 17.7595 8.42107L9.57764 0.239544C9.4242 0.0861641 9.21613 0 8.99918 0C8.78223 0 8.57416 0.0861641 8.42073 0.239544L0.238909 8.42107C0.0864639 8.5754 0.000677767 8.7834 0 9.00032V17.1818C0 17.3988 0.086201 17.6069 0.23964 17.7604C0.393079 17.9138 0.601187 18 0.818182 18H4.90909V13.9092C4.90909 12.8243 5.3401 11.7838 6.10729 11.0166C6.87448 10.2495 7.91502 9.81847 9 9.81847C10.085 9.81847 11.1255 10.2495 11.8927 11.0166C12.6599 11.7838 13.0909 12.8243 13.0909 13.9092V18H17.1818C17.3988 18 17.6069 17.9138 17.7604 17.7604C17.9138 17.6069 18 17.3988 18 17.1818V9.00032C17.9999 8.893 17.9785 8.78677 17.937 8.68779Z"
        fill="white"
      />
    </svg>
  );
}

export default HomeIcon;
