function InputCopyIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.875 0.5H4.875C4.70924 0.5 4.55027 0.565848 4.43306 0.683058C4.31585 0.800269 4.25 0.95924 4.25 1.125V4.25H1.125C0.95924 4.25 0.800269 4.31585 0.683058 4.43306C0.565848 4.55027 0.5 4.70924 0.5 4.875V14.875C0.5 15.0408 0.565848 15.1997 0.683058 15.3169C0.800269 15.4342 0.95924 15.5 1.125 15.5H11.125C11.2908 15.5 11.4497 15.4342 11.5669 15.3169C11.6842 15.1997 11.75 15.0408 11.75 14.875V11.75H14.875C15.0408 11.75 15.1997 11.6842 15.3169 11.5669C15.4342 11.4497 15.5 11.2908 15.5 11.125V1.125C15.5 0.95924 15.4342 0.800269 15.3169 0.683058C15.1997 0.565848 15.0408 0.5 14.875 0.5ZM10.5 14.25H1.75V5.5H10.5V14.25ZM14.25 10.5H11.75V4.875C11.75 4.70924 11.6842 4.55027 11.5669 4.43306C11.4497 4.31585 11.2908 4.25 11.125 4.25H5.5V1.75H14.25V10.5Z"
        fill="white"
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default InputCopyIcon;
