import { useEffect, useState } from "react";
import styles from "./AdsgramQuestCard.module.scss";
import GreeenDoneIcon from "../../assets/icons/GreenDoneIcon";
import RedErrorIcon from "../../assets/icons/RedErrorIcon.tsx";
import {useAdsgram} from "../../ads/hooks/useAdsgram.ts";
import {ShowPromiseResult} from "../../ads/types/adsgram";
import axios from "axios";
import crypto from 'crypto';
import {v4 as uuidv4} from "uuid";
import {API, encryptAdsToken} from "../../constants.tsx";
import {useAuthToken} from "../../hooks/useAuthToken.tsx";
// import CircularProgress from "@mui/joy/CircularProgress";


const generateUUID = () => {
    try {
        return crypto.randomUUID();
    } catch (error) {
        return uuidv4();
    }
}

const AdsGramLogo = ({width, height}: {width: number, height: number}) => {
  return (
      <svg width={width} height={height} viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#FFFFFF">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
              d="M5 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5Z"
              stroke="#FFFFFF" strokeWidth="1.416" strokeLinecap="round" strokeLinejoin="round"></path>
          <path d="M7 15V11C7 9.89543 7.89543 9 9 9V9C10.1046 9 11 9.89543 11 11V15" stroke="#FFFFFF"
                strokeWidth="1.416" strokeLinecap="round" strokeLinejoin="round"></path>
          <path d="M17 9V15" stroke="#FFFFFF" strokeWidth="1.416" strokeLinecap="round"
                strokeLinejoin="round"></path>
          <path d="M17 12H15.5C13.5 12 13.5 15 15.5 15H17" stroke="#FFFFFF" strokeWidth="1.416" strokeLinecap="round"
                strokeLinejoin="round"></path>
          <path d="M7 13H11" stroke="#FFFFFF" strokeWidth="1.416" strokeLinecap="round"
                strokeLinejoin="round"></path>
        </g>
      </svg>
  );
}


function AdsgramQuestCard() {
  const authToken = useAuthToken();
  const [adsToken, setAdsToken] = useState<string | null>(null);

  const [newCompletedQuest, setNewCompletedQuest] = useState<boolean>(false);
  const [errorCompletedQuest, setErrorCompletedQuest] = useState<boolean>(false);
  const [errorAdsQuest, setErrorAdsQuest] = useState<boolean>(false);

  const [showAlert, setShowAlert] = useState(false);
  const [animate, setAnimate] = useState<"open" | "close">("open");
  const [error, setError] = useState(false);

  const onReward = () => {
    if (adsToken === null) {
        setErrorCompletedQuest(true);
        return
    }
    axios
        .post(`${API}/tg/ads/submit`, {
          auth_token: authToken,
          ads_token: encryptAdsToken(adsToken),
        })
        .then((resp) => {
          if (resp.data.status === "ok") {
            setNewCompletedQuest(true);
          } else {
            setErrorCompletedQuest(true);
          }
          setAdsToken(null);
        })
  }

  const onError = (result: ShowPromiseResult) => {
    console.log(result);
    setErrorCompletedQuest(true);
  }

  const showAd = useAdsgram({blockId: "1162", onReward, onError})

  useEffect(() => {
    if (adsToken !== null) {
      showAd();
    }
  }, [adsToken]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setError(false);
        window.location.reload();
      }, 2500);
    }
  }, [error]);

  useEffect(() => {
    if (newCompletedQuest) {
      setShowAlert(true);
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setShowAlert(false);
        setNewCompletedQuest(false);
      }, 2500);
    }
  }, [newCompletedQuest]);

  useEffect(() => {
    if (errorCompletedQuest) {
      setShowAlert(true);
      setTimeout(() => {
        setAnimate("close");
      }, 3500);
      setTimeout(() => {
        setShowAlert(false);
        setErrorCompletedQuest(false);
      }, 3500);
    }
  }, [errorCompletedQuest]);

    useEffect(() => {
        if (errorAdsQuest) {
            setShowAlert(true);
            setTimeout(() => {
                setAnimate("close");
            }, 3500);
            setTimeout(() => {
                setShowAlert(false);
                setErrorAdsQuest(false);
            }, 3500);
        }
    }, [errorAdsQuest]);

  return (
      <>
        {error && (
            <div
                className={
                  styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
                }
            >
                <RedErrorIcon/>
              Something went wrong!
            </div>
        )}
        {showAlert && newCompletedQuest && (
            <div
                className={
                  styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
                }
            >
              <GreeenDoneIcon/>
              You received +50 Sleepcoins!
            </div>
        )}
        {showAlert && errorCompletedQuest && (
            <div
                className={
                  styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
                }
            >
              <RedErrorIcon/>
              You haven't completed the quest, please try again :(
            </div>
        )}
          {showAlert && errorAdsQuest && (
              <div
                  className={
                      styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
                  }
              >
                  <RedErrorIcon/>
                  No ads for you yet, get back in 10 minutes :)
              </div>
          )}
        <div
            className={styles.questDiv}
            onClick={() => {
              axios
                  .post(`${API}/tg/ads/start`, {
                    auth_token: authToken,
                    uuid: generateUUID()
                  })
                  .then((resp) => {
                    if (resp.data.status === "ok") {
                      setAdsToken(resp.data.adsToken);
                    } else {
                      setErrorAdsQuest(true);
                    }
                  })
            }}
            style={{
              cursor: "pointer",
            }}
        >
          <div className={styles.questInfo}>
            <div className={styles.imgContainer}>
              <AdsGramLogo width={20} height={20} />
            </div>
            <div className={styles.textDiv}>Watch Ad to Earn SleepCoins</div>
          </div>
          <div className={styles.questInfo}>
          <span
              style={{
                whiteSpace: "nowrap",
                color: "#fff",
              }}
          >
            +50 SC
          </span>
            <div className={styles.circle}/>
          </div>
        </div>
      </>
  );
}

export default AdsgramQuestCard;
